import React, { useEffect } from 'react';
import Resizer from "react-image-file-resizer";
import { MediaUpload } from '../../../Constant/api';

export default function PicturesUpload(props) {
  const {
    ImageArray,
    setImageArray,
    ImageArrayReload,
    setImageArrayReload,
  } = props;

  const fileRef = React.createRef();
  const fileRefSingle = React.createRef();
  const [Loading, setLoading] = React.useState(false)
  const MediaUploadCall = (file, i, length) => {
    setLoading(true)
    var fileInput = false;
    if (file) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          file,
          1000,
          1000,
          "JEPG",
          40,
          0,
          (uri) => {
            MediaUpload(uri)
              .then(res => res.json())
              .then(data => {
                ImageArray.push(data.source_url)
                // if (i == length - 1)
                setTimeout(() => {
                  setImageArrayReload(true)
                  setLoading(false)
                }, 1000);
                console.log(i)
              })
              .catch(err => { console.log("error", err.message); })
              .finally(() => { })
          },
          "file",
          200,
          200
        );
      } catch (err) {
        setLoading(false)

        console.log(err);
      }
    }
  }

  const MediaUploadSingleCall = (file, i) => {
    setLoading(true)
    var fileInput = false;
    if (file) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          file,
          1000,
          1000,
          "JPEG",
          40,
          0,
          (uri) => {

            MediaUpload(file)
              .then(res => res.json())
              .then(data => {
                ImageArray[i] = data.source_url
                setTimeout(() => {
                  setImageArrayReload(true)
                  setLoading(false)
                  // setImageArray(ImageArray)
                }, 1000);
              })
              .catch(err => { console.log("error", err.message); })
              .finally(() => { })
          },
          "file",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  }
  const MediaArrayShow = () => {
    var array = [];
    for (let i = 0; i < ImageArray.length; i++)
      array.push(
        <div className=" my-2" key={i}>
          <div className=" ">
            <div className="overflow-hidden">
              <input type="file" accept="image/*" name="image" id="file" ref={fileRefSingle}
                style={{ display: "none" }}
                onChange={(event) => {
                  setImageArrayReload(false)
                  setLoading(true)
                  MediaUploadSingleCall(event.target.files[0], i)
                }}
              />
            </div>
            <button type="button" onClick={() => { fileRefSingle.current.click() }}
              className="bg-transparent border-0 p-1 "
            >
              <img src={`${ImageArray[i]}`} className={"height-width-150 border border-dark"}
                style={{ width: "150px" }}
              />
            </button>
            <div style={{ cursor: "pointer" }}
              onClick={() => {
                setImageArrayReload(false)
                setLoading(true)
                for (let j = 0; j < ImageArray.length; j++) {
                  if (j == i) {
                    ImageArray.splice(j, 1)
                    setTimeout(() => {
                      setImageArrayReload(true)
                      setLoading(false)
                    }, 1000)
                    break;
                  }
                }

              }}
            >
              Delete Image
            </div>
          </div>
        </div>
      )
    return array;
  }
  return (
    <div>
      {/* {JSON.stringify(ImageArray)} */}
      {Loading &&
        <div className="spinner-border"></div>
      }
      {ImageArrayReload &&
        <MediaArrayShow />
      }
      <div className="col-lg-6 col-md-6">
        <div className="row">

          <div className="col-3">
            <button type="button" onClick={() => { fileRef.current.click() }}
              className="bg-transparent border-0 p-1 "
            >
              <div className="border height-width-150 border-dark m-1 p-4 font-size-12">
                <i className="fa fa-camera font-size-18"></i>
                <div>Add</div>
              </div>
            </button>
          </div>

        </div>

      </div>

      <div className="overflow-hidden">
        <input type="file" multiple accept="image/*" name="image" id="file" ref={fileRef}
          style={{ display: "none" }}
          onChange={(event) => {
            if (event.target.files !== 0) {
              setImageArrayReload(false)
              for (let i = 0; i < event.target.files.length; i++) {
                // MediaUploadCall(event.target.files[i], i, event.target.files.length - 1)
                MediaUploadSingleCall(event.target.files[i], ImageArray.length + i)
              }
            }
          }}
        />
      </div>


    </div>
  )
}